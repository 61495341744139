import React from "react"
import Layout from "../components/Layout"

export default function NotFound() {
  return (
    <Layout title="404">
      <h2>404</h2>
      <p>Sorry, this page doesn't exist!</p>
    </Layout>
  )
}
